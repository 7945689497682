import React, { useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import {
  AlgoliaKeywordSearch,
  AlgoliaLocationSearch,
} from '../../../../components';
import { useSelector } from 'react-redux';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { useConfiguration } from '../../../../context/configurationContext';
import { useIntl } from 'react-intl';
import { useGeoSearch, useSearchBox } from 'react-instantsearch';
import { createResourceLocatorString } from '../../../../util/routes';
import { parse } from '../../../../util/urlHelpers';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const SearchSection = props => {
  const [values, setValues] = useState(null);
  const {
    currentUser,
    currentUserLocationInfo,
    currentUserCurrency,
    exchangeRates,
    history,
    config,
    intl,
    queryParams,
    routeConfiguration,
  } = props;

  const {
    refine: refineLocation,
    clearMapRefinement,
    currentLocationRefinement,
  } = useGeoSearch(props);

  const { refine: refineKeyword } = useSearchBox(props);

  const { keyword: qKeyword, bounds: qBounds, address: qAddress } =
    queryParams || {};

  const handleKeywordChange = value => {
    refineKeyword(value);
    setValues(prev => {
      return {
        ...prev,
        keyword: value,
      };
    });
  };

  const handleLocationChange = value => {
    // const { address, bounds } = value;
    // const formattedBounds = {
    //   southWest: {
    //     lat: bounds.sw.lat,
    //     lng: bounds.sw.lng,
    //   },
    //   northEast: {
    //     lat: bounds.ne.lat,
    //     lng: bounds.ne.lng,
    //   },
    // };
    // refineLocation(formattedBounds);
    // setValues(prev => {
    //   return {
    //     ...prev,
    //     location: { bounds, address },
    //   };
    // });

    const { address, bounds } = value || {};
    if (address && bounds) {
      let searchParams = {
        address,
        bounds,
      };
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration,
          {},
          searchParams
        )
      );
    }
  };

  const handleSearchSubmit = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    
    const { keyword, location } = values || {};
    const { address, bounds: latlngBounds } = location || {};

    const searchParams = {
      ...(!!keyword?.trim() ? { keyword } : {}),
      ...(!!address?.trim() ? { address } : {}),
      ...(latlngBounds ? { bounds: latlngBounds } : {}),
    };
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {},
        searchParams
      )
    );
  };

  return (
    <div className={css.searchInputs}>
      {/* Requirement:Don't need form */}
      {/* <form className={css.searchInputs} onSubmit={handleSearchSubmit}> */}
      <AlgoliaKeywordSearch
        onSearchChange={handleKeywordChange}
        clearKeywordsClass={css.clearKeywords}
        micClass={css.micClass}
        initialValue={{ keyword: qKeyword }}
        history={history}
        currentUserCurrency={currentUserCurrency}
        currentUser={currentUser}
        exchangeRates={exchangeRates}
        routes={routeConfiguration}
        config={config}
        intl={intl}
        onFocus={() => {
          // Requirement to move to search page
          // when focused.
          handleSearchSubmit();
        }}
        name="keyword"
        searchInputText={intl.formatMessage({id:"AlgoliaKeywordSearch.placeholder"})}
        blockAutoScroll
      />
      <AlgoliaLocationSearch
        className={css.algoliaLocation}
        attribute={'publicData.location.address'}
        defaultLocation={currentUserLocationInfo}
        onRefineLocation={handleLocationChange}
        refineLocation={refineLocation}
        currentLocationRefinement={currentLocationRefinement}
        clearMapRefinement={clearMapRefinement}
        initialValues={{ bounds: qBounds, address: qAddress }}
        name="location"
      />
      {/* <button hidden type="submit">
                  submit
                </button> */}
      {/* </form> */}
    </div>
  );
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;

  const {
    currentUser,
    currentUserLocationInfo,
    currentUserCurrency,
  } = useSelector(s => s.user);

  const { exchangeRates } = useSelector(s => s.exchangeRate);

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const intl = useIntl();

  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields(
    [title, text, callToAction],
    options
  );

  const queryParams = parse(history?.location?.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          <Field
            data={callToAction}
            className={ctaButtonClass}
            options={options}
          />

          {/* Only render if on home page */}
          {blockId === 'hero-block-1' ? (
            <SearchSection
              currentUser={currentUser}
              currentUserLocationInfo={currentUserLocationInfo}
              currentUserCurrency={currentUserCurrency}
              exchangeRates={exchangeRates}
              history={history}
              config={config}
              intl={intl}
              queryParams={queryParams}
              routeConfiguration={routeConfiguration}
            />
          ) : null}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
